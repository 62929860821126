import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const HamburgerBlindsContainer = styled.ul`
  position: fixed;
  z-index: 200;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  top: 0;
  left: 0;
  display: none;

  li {
    background-color: #ed2c3e;
    width: calc(100% / 6);
    transform: scaleY(0) scaleX(1.05);
    transform-origin: center top;
  }
`;

const HamburgerBlinds = ({ hamBlindRefs, hamBlindContainerRef }) => {
  return (
    <HamburgerBlindsContainer ref={li => (hamBlindContainerRef[0] = li)}>
      {hamBlindRefs.map((blind, i) => {
        return (
          <li
            key={i}
            className={`hamBlind hamBlind${i}`}
            ref={li => (hamBlindRefs[i] = li)}
          ></li>
        );
      })}
    </HamburgerBlindsContainer>
  );
};

HamburgerBlinds.propTypes = {
  hamBlindRefs: PropTypes.array,
  hamBlindContainerRef: PropTypes.array,
};

export default HamburgerBlinds;
