import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Head from 'components/head';
import Header from 'components/header';
import GlobalStyle from 'global.css.js';
import Lines from 'components/lines';

const Layout = ({ data, children, handleScroll, location }) => {
  let segments = useRef(6);
  let pageTitle = useRef(null);
  let pageImage = useRef(null);

  data.allContentfulPost.edges.forEach(post => {
    if (location?.pathname === `/${post.node.slug}/`) {
      pageTitle.current = post.node.title;
      pageImage.current = `https:${post.node.hero.file.url}`;
    }
  });

  if (location?.pathname === '/about/') {
    console.log('about page');
    pageTitle.current = data.contentfulAbout.title;
    pageImage.current = `https:${data.contentfulAbout.gallery[0].file.url}`;
  }

  return (
    <div>
      <GlobalStyle />
      <Head
        pageTitle={pageTitle.current ? pageTitle.current : null}
        pageImage={pageImage.current ? pageImage.current : null}
      />
      <Header
        data={data}
        segments={segments.current}
        handleScroll={handleScroll}
        location={location}
      />
      <Lines numberOfLines={segments.current - 1} />
      {children}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  handleScroll: PropTypes.func,
  location: PropTypes.object,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
        allContentfulTimeline(
          sort: { order: DESC, fields: date }
          filter: { node_locale: { eq: "en-US" } }
        ) {
          edges {
            node {
              id
              title
              slug
              date
            }
          }
        }
        allContentfulPost(
          filter: { node_locale: { eq: "en-US" } }
          sort: { fields: publishDate, order: DESC }
        ) {
          edges {
            node {
              id
              slug
              title
              year
              hero {
                file {
                  url
                }
              }
              tag {
                title
              }
            }
          }
        }
        contentfulMotto {
          sideText1 {
            childMarkdownRemark {
              html
            }
          }
          sideText2 {
            childMarkdownRemark {
              html
            }
          }
          sideText3 {
            childMarkdownRemark {
              html
            }
          }
          motto {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
