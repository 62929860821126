import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import PropTypes from 'prop-types';

const TitleContainer = styled.div`
  overflow: hidden;
  position: relative;

  .titleTop {
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: white;
    position: absolute;
    transform: scaleY(0.025);
    transform-origin: top center;
    transition: transform 0.4s;
  }

  .titleBottom {
    display: flex;
    height: 42px;
    align-items: center;

    .scrollingTitleSub {
      display: flex;
      justify-content: space-between;
    }

    li {
      margin-right: 40px;
      white-space: nowrap;
      pointer-events: none;
      transition: color 0.4s;
    }
  }

  &:hover {
    .titleTop {
      transform: scaleY(1);
    }

    li {
      color: black;
    }
  }
`;

const ScrollingTitle = ({ title, width }) => {
  let headline1 = useRef(null);
  let headline2 = useRef(null);

  useEffect(() => {
    const textTl1 = gsap.timeline({ repeat: -1 });
    const textTl2 = gsap.timeline({ repeat: -1 });

    textTl1.to(headline1, 10, {
      x: '-100%',
      ease: 'none',
    });
    textTl2.to(headline2, 10, {
      x: '-100%',
      ease: 'none',
    });
  }, []);

  return (
    <TitleContainer className="titleContainer" width={width}>
      <div className="titleTop"></div>
      <div className="titleBottom">
        <ul
          className="scrollingTitleSub"
          ref={div => {
            headline1 = div;
          }}
        >
          <li>{title}</li>
          <li>{title}</li>
          <li>{title}</li>
          <li>{title}</li>
        </ul>
        <ul
          className="scrollingTitleSub"
          ref={div => {
            headline2 = div;
          }}
        >
          <li>{title}</li>
          <li>{title}</li>
          <li>{title}</li>
          <li>{title}</li>
        </ul>
      </div>
    </TitleContainer>
  );
};

ScrollingTitle.propTypes = {
  title: PropTypes.string,
  width: PropTypes.number,
};

export default ScrollingTitle;
