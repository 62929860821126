import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const HamburgerNavContainer = styled.nav`
  padding: 15vh 0 0 0;
  position: fixed;
  z-index: 300;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  pointer-events: auto;
  display: none;
  pointer-events: none;

  a:hover {
    color: #1c1c20;
  }

  .leftNav,
  .rightNav {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .timelineNav,
  .featuredFilmsNav {
    padding-bottom: 80px;
  }

  .leftNav {
    align-items: flex-end;

    .content {
      width: calc(100% * 2 / 3);
      overflow: hidden;
    }

    h3,
    li,
    p {
      transform: translateX(-110%);
      pointer-events: auto;
    }

    h3 {
      font-size: 0.7rem;
      font-weight: 700;
      padding-bottom: 15px;
    }

    li {
      padding: 6px 0;
      font-size: 1.8rem;

      .date {
        font-family: sans-serif;
        display: inline-block;
        font-size: calc(1.8rem * 0.275);
        transform: translateY(calc(-1.8rem * 0.6));
      }
    }

    p {
      padding: 8px 0 0 0;
      font-size: 1rem;
      margin: 0;
    }
  }

  .rightNav {
    align-items: center;

    ul {
      width: 100%;
      overflow: hidden;
    }

    li {
      pointer-events: auto;
      font-size: 9rem;
      transform: translateX(-110%);
    }
  }

  .marginTextContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;

    li {
      position: absolute;
      text-align: center;
      pointer-events: auto;

      p {
        margin: 0;
        padding: 0;
        white-space: nowrap;
      }
    }

    .marginText {
      opacity: 0;
    }

    .marginText1 {
      top: 2rem;
      left: 50%;
      transform: translateX(-50%);
    }

    .marginText2 {
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
      writing-mode: vertical-rl;
    }

    .marginText3 {
      bottom: 2rem;
      left: 50%;
      transform: translateX(-50%);
    }

    .marginText4 {
      left: 2rem;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      writing-mode: vertical-lr;
    }
  }

  @media (max-width: 1200px) {
    .leftNav {
      width: 45%;

      li {
        font-size: 2.3vw;

        .date {
          font-size: calc(2.3vw * 0.275);
          transform: translateY(calc(-2.3vw * 0.6));
        }
      }
    }

    .rightNav {
      width: 55%;
      li {
        font-size: 11.5vw;
      }
    }
  }

  @media (max-width: 700px) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding-top: 9vh;

    .leftNav,
    .rightNav {
      width: 100%;
    }

    .leftNav {
      padding-top: 30px;
      align-items: center;
      .content {
        width: 70%;
        display: flex;
        overflow-x: visible;
      }

      li {
        font-size: 1.2rem;

        .date {
          font-size: calc(1.2rem * 0.275);
          transform: translateY(calc(-1.2rem * 0.6));
        }
      }
    }

    .rightNav {
      ul {
        margin: 0 auto;
        width: 70%;
      }

      li {
        font-size: 5.5rem;
      }
    }

    .timelineNav,
    .featuredFilmsNav {
      width: 50%;
      padding-bottom: 0;
      overflow-x: hidden;
    }

    .marginTextContainer {
      .marginText1 {
        top: 0.75rem;
      }

      .marginText2 {
        right: 0.75rem;
      }

      .marginText4 {
        left: 0.75rem;
      }
    }
  }

  @media (max-width: 500px) {
    padding-top: 4vh;
    padding-left: 7vw;

    .timelineNav {
      display: none;
    }

    .featuredFilmsNav {
      min-width: 200px;

      // li:last-child,
      // li:nth-last-child(2),
      // li:nth-last-child(3) {
      //   display: none;
      // }
    }

    .rightNav li {
      font-size: 16vw;
    }

    .marginText1,
    .marginText2,
    .marginText4 {
      display: none;
    }
  }
`;

const HamburgerNav = ({
  data,
  hamburgerOpen,
  timelineRefs,
  postRefs,
  marginRefs,
  linkRefs,
  hamNavContainerRef,
  handleNavigate,
  featuredPosts,
}) => {
  const { allContentfulPost, allContentfulTimeline, contentfulMotto } = data;

  return (
    <HamburgerNavContainer
      hamburgerOpen={hamburgerOpen}
      ref={nav => (hamNavContainerRef[0] = nav)}
    >
      <div className="leftNav">
        <div className="content">
          {/* <div className="timelineNav">
            <h3 ref={h3 => (timelineRefs[0] = h3)}>Timeline</h3>
            <ul>
              {allContentfulTimeline.edges.slice(0, 3).map((timeline, i) => {
                return (
                  <li
                    key={timeline.node.id}
                    ref={li => (timelineRefs[i + 1] = li)}
                  >
                    <a
                      href="/#timeline"
                      onClick={e => {
                        e.preventDefault();
                        handleNavigate('#timeline');
                      }}
                    >
                      {timeline.node.title}
                      <span className="date">{timeline.node.date}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
            <a
              className="andMore"
              href="/#timeline"
              onClick={e => {
                e.preventDefault();
                handleNavigate('#timeline');
              }}
            >
              <p ref={h3 => (timelineRefs[4] = h3)}>and more</p>
            </a>
          </div> */}
          <div className="featuredFilmsNav">
            <h3 ref={h3 => (postRefs[0] = h3)}>Featured Films</h3>
            <ul>
              {featuredPosts.map((post, i) => {
                return (
                  <li key={post.node.id} ref={li => (postRefs[i + 1] = li)}>
                    <a
                      href={`/${post.node.slug}/`}
                      onClick={e => {
                        e.preventDefault();
                        handleNavigate(`/${post.node.slug}/`);
                      }}
                    >
                      {post.node.title}
                      <span className="date">{post.node.year}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
            <a
              className="andMore"
              href="/#films"
              onClick={e => {
                e.preventDefault();
                handleNavigate('#films');
              }}
            >
              <p ref={h3 => (postRefs[postRefs.length - 1] = h3)}>and more</p>
            </a>
          </div>
        </div>
      </div>
      <div className="rightNav">
        <ul>
          <li ref={li => (linkRefs[0] = li)}>
            <a
              href="/#films"
              onClick={e => {
                e.preventDefault();
                handleNavigate('#films');
              }}
            >
              Films
            </a>
          </li>
          <li ref={li => (linkRefs[1] = li)}>
            <a
              href="/#timeline"
              onClick={e => {
                e.preventDefault();
                handleNavigate('#timeline');
              }}
            >
              Timeline
            </a>
          </li>
          <li ref={li => (linkRefs[2] = li)}>
            <a
              href="/#contact"
              onClick={e => {
                e.preventDefault();
                handleNavigate('#contact');
              }}
            >
              Contact
            </a>
          </li>
          <li ref={li => (linkRefs[3] = li)}>
            <a
              href="/about"
              onClick={e => {
                e.preventDefault();
                handleNavigate('/about');
              }}
            >
              About
            </a>
          </li>
        </ul>
      </div>
      <ul className="marginTextContainer">
        <li
          className="marginText marginText1"
          dangerouslySetInnerHTML={{
            __html: contentfulMotto.sideText1.childMarkdownRemark.html,
          }}
          ref={li => (marginRefs[0] = li)}
        />
        <li
          className="marginText marginText2"
          dangerouslySetInnerHTML={{
            __html: contentfulMotto.sideText2.childMarkdownRemark.html,
          }}
          ref={li => (marginRefs[1] = li)}
        />

        <li
          className="marginText marginText3"
          dangerouslySetInnerHTML={{
            __html: contentfulMotto.motto.childMarkdownRemark.html,
          }}
          ref={li => (marginRefs[2] = li)}
        />
        <li
          className="marginText marginText4"
          dangerouslySetInnerHTML={{
            __html: contentfulMotto.sideText3.childMarkdownRemark.html,
          }}
          ref={li => (marginRefs[3] = li)}
        />
      </ul>
    </HamburgerNavContainer>
  );
};

HamburgerNav.propTypes = {
  data: PropTypes.object,
  hamburgerOpen: PropTypes.bool,
  timelineRefs: PropTypes.array,
  postRefs: PropTypes.array,
  marginRefs: PropTypes.array,
  linkRefs: PropTypes.array,
  hamNavContainerRef: PropTypes.array,
  handleNavigate: PropTypes.func,
  featuredPosts: PropTypes.array,
};

export default HamburgerNav;
