import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import StellarLogo from 'images/stellar.svg';
import HamburgerNav from 'components/hamburgerNav';
import HamburgerBlinds from 'components/hamburgerBlinds';
import gsap from 'gsap';

const Container = styled.header`
  padding: 0;
  pointer-events: none;

  .hamburgerMenuContainer,
  .logoContainer,
  .icons {
    opacity: 0;
    position: fixed;
    z-index: 300;
  }

  .hamburgerMenuContainer {
    top: 2rem;
    right: 2rem;
  }

  .hamburger {
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    span {
      width: 30px;
      background-color: white;
      height: 1px;
    }
  }

  .logoContainer {
    left: 2rem;
    top: 2rem;

    font-size: 1.6rem;
    display: block;

    .cls-1 {
      fill: '#ed2c3e';
    }
  }

  .icons {
    bottom: 3rem;
    left: 2rem;

    display: flex;
    flex-direction: column;
    color: white;
    width: 16px;
  }

  .svg-inline--fa {
    pointer-events: auto;
    margin-top: 40px;
  }

  span {
    display: block;
  }

  a {
    color: white;
    transition: color 0.2s ease;
    text-decoration: none;
  }

  .stellarLogo {
    width: 40px;
  }

  @media (max-width: 700px) {
    .hamburgerMenuContainer {
      top: 0.75rem;
      right: 0.75rem;
    }

    .logoContainer {
      top: 0.75rem;
      left: 0.75rem;
    }

    .icons {
      left: 0.75rem;
      bottom: 2rem;
    }
  }
`;

const Header = ({ data, segments, handleScroll, location }) => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const featuredPosts = data.allContentfulPost.edges.filter(post => {
    if (post?.node?.tag) {
      return post?.node?.tag[0]?.title === 'Featured';
    }
  });

  let hamNavContainerRef = [];
  let hamBlindContainerRef = [];
  let hamBlindRefs = [];
  let hamBarTopRef = useRef(null);
  let hamBarBotRef = useRef(null);

  for (let i = 0; i < segments; i++) {
    if (i === 0) {
      hamNavContainerRef.push(useRef(null));
      hamBlindContainerRef.push(useRef(null));
    }

    hamBlindRefs.push(useRef(null));
  }

  let timelineRefs = [];
  let postRefs = [];
  let marginRefs = [];
  let linkRefs = [];

  for (let i = 0; i < featuredPosts.length + 2; i++) {
    if (i < 4) {
      linkRefs.push(useRef(null));
      marginRefs.push(useRef(null));
    }

    if (i < 5) {
      timelineRefs.push(useRef(null));
    }

    postRefs.push(useRef(null));
  }

  const handleClick = () => {
    const blindsTl = gsap.timeline();
    const timelineTL = gsap.timeline();
    const postTL = gsap.timeline();
    const linkTL = gsap.timeline();
    const marginTL = gsap.timeline();

    if (!hamburgerOpen) {
      blindsTl
        .set(hamNavContainerRef[0], { display: 'flex' })
        .set(hamBlindContainerRef[0], { display: 'flex' })
        .staggerTo(hamBlindRefs, 0.4, { scaleY: 1, scaleX: 1.01 }, 0.05)
        .set(hamBlindContainerRef[0], { backgroundColor: '#ed2c3e' });

      gsap.to('.logoContainer .cls-1', 0.2, { fill: 'white' });
      gsap.to(hamBarTopRef, 0.4, { rotate: '225deg', x: '0', y: '5px' });
      gsap.to(hamBarBotRef, 0.4, { rotate: '-225deg', x: '0', y: '-6px' });
      // timelineTL.staggerTo(timelineRefs, 0.4, { x: '0%', delay: 0.2 }, 0.05);
      postTL.staggerTo(postRefs, 0.4, { x: '0%', delay: 0.2 }, 0.05);
      linkTL.staggerTo(linkRefs, 0.4, { x: '0%', delay: 0.2 }, 0.05);
      marginTL.to(marginRefs, 0.4, { opacity: 1, delay: 0.4 });
    } else {
      blindsTl
        .set(hamBlindContainerRef[0], { backgroundColor: 'transparent' })
        .staggerTo(
          hamBlindRefs,
          0.4,
          { delay: 0.2, scaleY: 0, scaleX: 1.01 },
          -0.05
        )
        .set(hamBlindContainerRef[0], { display: 'none' })
        .set(hamNavContainerRef[0], { display: 'none' });

      gsap.to('.logoContainer .cls-1', 0.2, {
        fill: '#ed2c3e',
        delay: 0.75,
      });
      gsap.to(hamBarTopRef, 0.4, { rotate: '0deg', x: '0px', y: '0px' });
      gsap.to(hamBarBotRef, 0.4, { rotate: '0deg', x: '0px', y: '0px' });
      // timelineTL.staggerTo(timelineRefs, 0.4, { x: '-110%' }, -0.05);
      postTL.staggerTo(postRefs, 0.4, { x: '-110%' }, -0.05);
      linkTL.staggerTo(linkRefs, 0.4, { x: '-110%' }, -0.05);
      marginTL.to(marginRefs, 0.4, { opacity: 0 });
    }

    setHamburgerOpen(!hamburgerOpen);
  };

  const handleNavigate = destination => {
    if (location.pathname === destination) {
      if (hamburgerOpen) {
        handleClick();
      }
      handleScroll('top', 0, 1000);
    } else if (location.pathname === '/' && destination.slice(0, 1) === '#') {
      handleClick();
      handleScroll(destination, 0, 1000);
    } else if (destination.slice(0, 1) === '#') {
      navigate('/', {
        state: { scrollTo: destination },
      });
    } else {
      navigate(destination);
    }
  };

  return (
    <Container hamburgerOpen={hamburgerOpen} id="headerContainer">
      <div style={{ pointerEvents: 'auto' }} className="logoContainer">
        <a
          href="/"
          onClick={e => {
            e.preventDefault();
            handleNavigate('/');
          }}
        >
          <StellarLogo className="stellarLogo" />
        </a>
      </div>
      <div style={{ pointerEvents: 'auto' }} className="hamburgerMenuContainer">
        <button className="hamburger" onClick={() => handleClick()}>
          <span className="topBar" ref={span => (hamBarTopRef = span)}></span>
          <span
            className="bottomBar"
            ref={span => (hamBarBotRef = span)}
          ></span>
        </button>
      </div>
      <div className={'icons'}>
        <a
          href="https://www.instagram.com/stellarcitizens/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          href="https://twitter.com/stellarcitizens?lang=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a href="mailto: info@stellarcitizens.com">
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
      </div>

      <HamburgerNav
        data={data}
        hamNavContainerRef={hamNavContainerRef}
        hamburgerOpen={hamburgerOpen}
        timelineRefs={timelineRefs}
        postRefs={postRefs}
        marginRefs={marginRefs}
        linkRefs={linkRefs}
        handleScroll={handleScroll}
        handleClick={handleClick}
        handleNavigate={handleNavigate}
        location={location}
        featuredPosts={featuredPosts}
      />
      <HamburgerBlinds
        hamBlindRefs={hamBlindRefs}
        hamBlindContainerRef={hamBlindContainerRef}
      />
    </Container>
  );
};

Header.propTypes = {
  data: PropTypes.object,
  segments: PropTypes.number,
  handleScroll: PropTypes.func,
  location: PropTypes.object,
};

export default Header;
