import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LinesContainer = styled.div`
  position: absolute;
  pointer-events: none;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: -10%;
  transform: scaleY(0);
  transform-origin: center top;
  z-index: 100;

  display: flex;
  justify-content: space-evenly;

  .line {
    width: 1px;
    background-color: rgba(255, 255, 255, 0.1);
    position: relative;
  }

  @media (max-width: 950px) {
    .line:nth-child(2n + 1) {
      opacity: 0;
    }
  }
`;

const Lines = ({ numberOfLines }) => {
  const lineArr = new Array(numberOfLines);

  for (let i = 0; i < lineArr.length; i++) {
    lineArr[i] = i;
  }

  return (
    <LinesContainer id="linesContainer">
      {lineArr.map((line, key) => {
        return <div key={key} className={`line line${line}`}></div>;
      })}
    </LinesContainer>
  );
};

Lines.propTypes = {
  numberOfLines: PropTypes.number,
};

export default Lines;
