import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import PropTypes from 'prop-types';

const FooterContainer = styled.footer`
  padding-bottom: 4rem;
  opacity: 0;
  position: relative;

  .motto,
  .companyInfo {
    position: absolute;
    top: 0;
  }

  .motto {
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;

    p {
      padding: 0;
      margin: 0;
    }
  }

  .companyInfo {
    right: 2rem;
    transform: translateY(0);
    font-size: 0.9rem;
    line-height: 1.4;
  }

  @media (max-width: 650px) {
    padding-bottom: 2.7rem;

    .motto {
      display: none;
    }
  }
`;

const Footer = ({ data }) => {
  let footerRef = useRef(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    setYear(new Date().getYear() + 1900);

    gsap.to(footerRef, 0.4, { delay: 0.4, opacity: 1 });
  });

  return (
    <FooterContainer
      data-scroll-section
      ref={footer => {
        footerRef = footer;
      }}
    >
      <div
        className="motto"
        dangerouslySetInnerHTML={{
          __html: data.motto.childMarkdownRemark.html,
        }}
      />
      <div className="companyInfo">Stellar Citizens, &copy; {year}</div>
    </FooterContainer>
  );
};

Footer.propTypes = {
  data: PropTypes.object,
};

export default Footer;
