import React from 'react';
import PropTypes from 'prop-types';

const Video = ({ videoSrcURL, className, description }) => (
  <>
    <video
      className={`video ${className}`}
      src={videoSrcURL}
      alt={description}
      muted
      playsInline
      autoPlay="autoplay"
      loop
    ></video>
  </>
);

Video.propTypes = {
  videoSrcURL: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
};

export default Video;
