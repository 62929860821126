import React from 'react';
import Video from './video.js';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const Media = ({ fluid, videoCheck, videoSrcURL, title, description }) => (
  <>
    {videoCheck === 'mp4' ? (
      <Video
        videoSrcURL={videoSrcURL}
        className={`${title}Video videoContiner`}
        description={description}
      />
    ) : (
      <Img
        fluid={fluid}
        alt={description}
        className={`${title}Image imageContainer`}
      />
    )}
  </>
);

Media.propTypes = {
  fluid: PropTypes.object,
  videoCheck: PropTypes.string,
  videoSrcURL: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Media;
