import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import TriangleWhite from 'images/triangleWhite.svg';

const CursorContainer = styled.div`
  pointer-events: none;
  transition: all 200ms ease-out;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  width: 50px;
  height: 50px;

  .circle {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    transition: scale 1000ms ease-in-out;
  }

  .triangle {
    width: 50%;
    opacity: 0;
    transform: rotate(-90deg) translate(-50%, -40%);
    transform-origin: top left;
    transition: scale 1000ms ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (pointer: coarse), (pointer: none) {
    display: none;

    .circle,
    .triangle {
      display: none;
    }
  }
`;

const Cursor = () => {
  let cursorRef = useRef(null);
  let circleRef = useRef(null);
  let triangleRef = useRef(null);

  const handleCursor = e => {
    const x = e.clientX;
    const y = e.clientY;
    cursorRef.style.transform = `translate3d(${x}px, ${y}px, 0)`;
  };

  const handleMouseDown = () => {
    circleRef.style.scale = 1.5;
  };

  const handleMouseUp = () => {
    circleRef.style.scale = 1;
  };

  let cursorToggle = useRef(false);

  const handleCursorSwitch = () => {
    cursorToggle.current = !cursorToggle.current;

    const tl = gsap.timeline();

    gsap.killTweensOf(circleRef);
    gsap.killTweensOf(triangleRef);

    if (cursorToggle.current) {
      tl.to(circleRef, 0.2, { autoAlpha: 0 }).to(triangleRef, 0.2, {
        autoAlpha: 1,
      });
    } else {
      tl.to(triangleRef, 0.2, { autoAlpha: 0 }).to(circleRef, 0.2, {
        autoAlpha: 1,
      });
    }
  };

  useEffect(() => {
    gsap.to(cursorRef, 0.6, { delay: 1, autoAlpha: 1 });

    document.addEventListener('mousemove', handleCursor);

    const slidesOuter = document.getElementById('slidesOuter');

    if (slidesOuter) {
      slidesOuter.addEventListener('mousedown', handleMouseDown);
    }

    document.addEventListener('mouseup', handleMouseUp);

    const filmContainer = document.getElementById('filmContainer');

    if (filmContainer) {
      filmContainer.addEventListener('mouseenter', handleCursorSwitch);
      filmContainer.addEventListener('mouseleave', handleCursorSwitch);
    }

    return () => {
      document.removeEventListener('mousemove', handleCursor);

      if (slidesOuter) {
        slidesOuter.removeEventListener('mousedown', handleMouseDown);
      }

      document.removeEventListener('mouseup', handleMouseUp);

      if (filmContainer) {
        filmContainer.removeEventListener('mouseenter', handleCursorSwitch);
        filmContainer.removeEventListener('mouseleave', handleCursorSwitch);
      }
    };
  }, []);

  return (
    <CursorContainer
      className="circle"
      ref={div => {
        cursorRef = div;
      }}
    >
      <div
        className="circle"
        ref={div => {
          circleRef = div;
        }}
      ></div>
      <div className="triangle" ref={div => (triangleRef = div)}>
        <TriangleWhite className={'white'} />
      </div>
    </CursorContainer>
  );
};

export default Cursor;
